import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { FooterAlternate1 } from "../components/footer"
import SEO from "../components/seo"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Knowledge Centre" />

    <header className="header header--basic ">
        <div className="header__content">
            <div className="container">
                <div className="row">
                    <div className="header__inner col-md-9 col-lg-7 col-lg-offset-1">
                        <h1 className="header__title">Outsourced Sales & Marketing </h1>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div className="landing landing--base outsource-sales-content">
        <div className="container outsource-sales-content-row" >
                <div className="row">
                    <div className="rich-text col-xs-12 col-sm-6 col-lg-11">
                        <h2 className="landing_column_header">Outsourced Sales Enablement.​</h2>
                        <p>With our international team of modern selling subject matter experts, SalesTribe delivers a Sales Enablement POD of subject matter experts to build the foundations of your GTM approach. Each SME is hand-picked to bring specialist leadership knowledge, expertise and value to your business. </p>
                        <p><b> Bespoke Sales Enablement programs </b>– we customise a POD of Sales Enablement subject matter experts to address all of the key foundations of how you go-to-market: Strategy, Planning and Execution.  </p>
                    </div>
                </div>
                <div className="row">
                    <div  className="outsource-sales-content-image col-xs-12 col-sm-6 col-lg-11">
                         <img src="../media/svg/landing-552X580_outsource_sales_marketing_outsourced-sales-enablement.svg" alt="" />
                    </div>
                </div>
        </div>
        <div className="container outsource-sales-content-row">
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-lg-11">
                        <h2 className="landing_column_header">Outsourced Sales Development (SDR/BDR)​​</h2>
                        <p>Old tactics will no longer open new doors, and Sales Development is now the most difficult component of sales execution: relentless prospecting, extremely low response rates and frequent rejection. ​</p>
                        <p>The Future of Work has spawned hybrid and remote working models which increases the need for more cost-effective and efficient ways for businesses to acquire and retain customers. </p>
                        <p>Identifying, hiring, onboarding and retaining your own salespeople has just become increasingly difficult. Costs associated with continued sales staff attrition is killing many businesses.  </p>
                        <p>Outsource the risk, and the high cost of acquisition to SalesTribe. We create the conversations allowing your team to focus on doing what they do best – solving your clients problems and generating your revenues.</p>
                    </div>
                </div>
                <div className="row">
                    <div  className="col-sm-12 col-md-pull-1">
                         <img   src="../media/images/landing-552X580_outsource_sales_marketing-outsourced-sales-development.png" alt="" />
                    </div>
                </div>
        </div>
        <div className="container outsource-sales-content-row">
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-lg-11">
                        <h2 className="landing_column_header">Fractional Sales Leadership​​​</h2>
                        <p>Our Tribe comprises some of the world's best Sales Leaders, and you can now bring one of our certified Sales Leaders into company on a short-term basis, helping you to develop and implement a modern sales strategy, train your sales team, and manage all aspects of your sales operations.</p>
                        <p>Gain access to one of our experienced Sales Leaders at a much lower cost compared to hiring a full-time sales executive, with the added flexibility of being able to adjust the level of support as needed. ​</p>
                    </div>
                </div>
                <div className="row">
                    <div className="outsource-sales-content-image col-xs-12 col-sm-6 col-lg-11">
                         <img src="../media/svg/landing-552X580_outsource_sales_marketing_fractional_sales_leadership.svg" alt="" />
                    </div>
                </div>
        </div>
        <div className="container outsource-sales-content-row">
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-lg-11">
                        <h2 className="landing_column_header">Market Entry Services​​</h2>
                        <p>Are you looking to establish your business presence in the APAC Market? ​</p>
                        <p>SalesTribe can help you navigate the complexities of a new market and minimize risks associated with entering it.​</p>
                        <p>Our market entry services can include market research and analysis, go-to-market advisory, regulatory and legal compliance, sales and distribution channel establishment, ​
                        Most importantly, we can represent your business in-market and become your team on the ground to help you accelerate (and de-risk) growth. ​</p>
                        <p>We help rapidly amplify your brand through our unmatched TRIBE and network of connections. ​</p>
                    </div>
                </div>
                <div className="row">
                    <div className="outsource-sales-content-image col-xs-12 col-sm-6 col-lg-11">
                         <img src="../media/images/outsource_sales_marketing.png" alt="" />
                    </div>
                </div>
        </div>
    </div>
  </Layout>
)

export default IndexPage
